<template>
    <div>
        <div class="page-title">机构基础信息</div>
        
        <pay-box title="基础内容">
            
            <a slot="extra" class="edit-btn" @click="activeBaseInfoModal">修改</a>
            
            <div v-if="config">
                
                <table class="at-table addr-table">
                    <tr>
                        <th>服务机构名称</th>
                        <td>{{ $app.nickname }}</td>
                    </tr>
                    <tr>
                        <th>报告显示价格{{ $app.payment.currency == 'CNY'?'':`(${$app.payment.symbol})` }}</th>
                        <td>{{ config.display_amount }}</td>
                    </tr>
                    
                    <template v-if=" $app.model !== 'person' ">
                        <tr>
                            <th>用户支付价格{{ $app.payment.currency == 'CNY'?'':`(${$app.payment.symbol})` }}</th>
                            <td>{{ config.pay_amount || '0.00' }}</td>
                        </tr>
                    </template>
                    
                    <tr>
                        <th>封面图片上传</th>
                        <td>
                            <div class="pdf-uploader">
                                <upload-image v-model="config.cover" @change=" file => updateAttr('cover',file)  "/>
                            </div>
                            <div class="tips">A4页面建议尺寸，2480*3507，图片最大为2M</div>
                            <div class="tips">未上传默认使用模板封面</div>
                        </td>
                    </tr>
                    
                    <tr>
                        <th>封底图片上传</th>
                        <td>
                            <div class="pdf-uploader">
                                <upload-image v-model="config.cover_back"
                                              @change=" file => updateAttr('cover_back',file)  "/>
                            </div>
                            <div class="tips">A4页面建议尺寸，2480*3507，图片最大为2M</div>
                            <div class="tips">未上传默认使用模板封底</div>
                        </td>
                    </tr>
                
                </table>
            
            </div>
        </pay-box>
        
        <pay-box title="机构自定义页面" sub-title="（这2页会印刷到实体报告上）">
            <table class="at-table addr-table">
                <tr>
                    <th>图片</th>
                    <td>
                        <div class="pdf-uploader-container">
                            <div class="pdf-uploader">
                                <upload-image v-model="config.page4" @change=" file => updateAttr('page4',file)  "/>
                            </div>
                            
                            <div class="pdf-uploader">
                                <upload-image v-model="config.page5" @change=" file => updateAttr('page5',file)  "/>
                            </div>
                        </div>
                        
                        <div class="tips">2页A4页面须上传内容，否则实体报告该位置会出现空白页</div>
                        <div class="tips">建议尺寸，2480*3507，图片最大为2M</div>
                    </td>
                </tr>
            </table>
        </pay-box>
        
        
        <pay-box title="风格产品营销页" sub-title="（上传后会替换小程序现有风格板块长图页面）">
            
            <table class="at-table addr-table">
                
                <tr>
                    <th>图片</th>
                    <td>
                        <div class="pdf-uploader-container">
                            <div class="pdf-uploader">
                                <upload-image v-model="config.custom_index"
                                              @change=" file => updateAttr('custom_index',file)  "/>
                            </div>
                        </div>
                        
                        <div class="tips">不上传就显示目前默认详情页</div>
                        <div class="tips">图片最大为2M</div>
                    </td>
                </tr>
            
            </table>
        
        </pay-box>
        
        <pay-box title="机构收货地址">
            
            <a class="edit-btn" slot="extra" @click="activeAddressModal">修改</a>
            
            <div v-if="addressInfo.receive_name">
                
                <table class="at-table addr-table">
                    <tr>
                        <th>收件人</th>
                        <td>{{ addressInfo.receive_name }}</td>
                    </tr>
                    <tr>
                        <th>电话</th>
                        <td>{{ addressInfo.receive_phone }}</td>
                    </tr>
                    <tr>
                        <th>收货地址</th>
                        <td>
                            <div>
                                <span>{{ addressInfo.receive_province }}</span>
                                <span>&nbsp;{{ addressInfo.receive_city }}</span>
                                <span>&nbsp;{{ addressInfo.receive_area }}</span>
                                <span>&nbsp;{{ addressInfo.receive_address }}</span>
                            </div>
                        </td>
                    </tr>
                </table>
            
            </div>
            <a-empty v-else></a-empty>
        
        </pay-box>
        
        <pay-box title="形象机构logo">
            
            <table class="at-table addr-table">
                <tr>
                    <th>图片</th>
                    <td>
                        <div class="pdf-uploader-container">
                            
                            <div class="pdf-uploader">
                                <upload-image v-model="config.company_logo"
                                              @change=" file => updateAttr('company_logo',file)  "/>
                            </div>
                        
                        </div>
                        
                        <div class="tips">图片最大为2M</div>
                    </td>
                    <th>
                        <span class="mr-2">客服二维码</span>
                        <at-tips>
                            <img class="full-with" src="/assets/images/service-tips.png" alt="">
                        </at-tips>
                    </th>
                    <td>
                        <div class="pdf-uploader-container">
                            
                            <div class="pdf-uploader">
                                <upload-image v-model="config.service_image"
                                              @change=" file => updateAttr('service_image',file)  "/>
                            </div>
                        
                        </div>
                        
                        <div class="tips">图片最大为2M</div>
                    </td>
                </tr>
            </table>
        
        </pay-box>
        
        <pay-box title="类型说明">
            <div class="documents">
                <a target="_blank"
                   href="https://static.ycsd.work/collocation-helper/pdf-summary/nv.pdf">颜创女性风格类型分类说明</a>
                <a target="_blank"
                   href="https://static.ycsd.work/collocation-helper/pdf-summary/nan.pdf">颜创男性风格类型分类说明</a>
                <a target="_blank"
                   href="https://static.ycsd.work/collocation-helper/pdf-summary/color.pdf">颜创色彩类型判断说明</a>
            </div>
        </pay-box>
        
        <a-modal v-model="showAddressModal" title="机构收货地址" @ok="updateAddressInfo">
            <at-input class="addr-input" unit="姓名" v-model="addressInfo.receive_name"/>
            <at-input class="addr-input" unit="电话" v-model="addressInfo.receive_phone"/>
            <at-input class="addr-input" unit="省份" v-model="addressInfo.receive_province"/>
            <at-input class="addr-input" unit="城市" v-model="addressInfo.receive_city"/>
            <at-input class="addr-input" unit="区县" v-model="addressInfo.receive_area"/>
            <at-input class="addr-input" unit="地址" v-model="addressInfo.receive_address"/>
        </a-modal>
        
        <a-modal v-model="showBaseInfoModal" title="基础内容" @ok="updateBasicInfo">
            <at-input class="addr-input" unit="报告显示价格" v-model="basicInfo.display_amount"/>
            <template v-if=" $app.model !== 'person' ">
                <at-input class="addr-input" unit="用户支付价格" v-model="basicInfo.pay_amount"/>
            </template>
        </a-modal>
    
    </div>
</template>

<script>
import UploadImage from "@/components/UploadImage.vue";
import AtInput from "@/components/AtInput.vue";
import api from "@/repo/api";
import PayBox from "@/views/AST/Pay/Step/PayBox.vue";
import AtTips from "@/components/AtTips.vue";

export default {
    name: "Basic",
    components: {AtTips, PayBox, AtInput, UploadImage},
    data() {
        return {
            showAddressModal: false,
            showBaseInfoModal: false,
            addressInfo: {
                receive_name: '',
                receive_phone: '',
                receive_province: '',
                receive_city: '',
                receive_area: '',
                receive_address: ''
            },
            basicInfo: {
                pay_amount: '0.00',
                display_amount: '0.00',
                company_name: '',
            },
            config: {},
        }
    },
    mounted() {
        this.getSettingInfo()
    },
    methods: {
        activeBaseInfoModal() {
            this.showBaseInfoModal = true
        },
        activeAddressModal() {
            this.showAddressModal = true
        },
        loadInfo(info) {
            this.addressInfo.receive_province = info.receive_province
            this.addressInfo.receive_city = info.receive_city
            this.addressInfo.receive_area = info.receive_area
            this.addressInfo.receive_address = info.receive_address
            this.addressInfo.receive_name = info.receive_name
            this.addressInfo.receive_phone = info.receive_phone
            
            this.basicInfo.company_name = info.company_name
            this.basicInfo.display_amount = info.display_amount
            this.basicInfo.pay_amount = info.pay_amount
        },
        getSettingInfo() {
            
            api.get('/ast-detect/config', data => {
                this.config = data.data
                this.loadInfo(this.config)
            })
            
        },
        updateBasicInfo() {
            this.updateInfo('/ast-detect/update-basic-info', this.basicInfo, () => {
                this.showBaseInfoModal = false
            })
        },
        updateAddressInfo() {
            this.updateInfo('/ast-detect/update-address-info', this.addressInfo, () => {
                this.showAddressModal = false
            })
        },
        updateInfo(url, data, success) {
            this.$loading.show()
            
            api.post(url, data, (res) => {
                
                this.$message.data(res)
                this.$loading.hide()
                
                if (res.code === 0) {
                    
                    if (typeof success === 'function') success()
                    
                    this.getSettingInfo()
                }
                
            })
        },
        updateAttr(name, value) {
            
            console.log('updateAttr', name, value);
            
            this.$loading.show()
            
            api.post('/ast-detect/update-single', {
                key: name,
                value
            }, res => {
                
                this.$loading.hide()
                
                this.$message.data(res)
                
                if (res.code !== 0) return;
                
                this.config[name] = value
            })
            
        }
    }
}
</script>

<style scoped lang="less">
.pdf-uploader-container {
    display: flex;
    justify-content: flex-start;
}

.tips {
    font-size: 12px;
    color: #a4a4a4;
}

.divider-sd {
    margin: 10px 0;
}

.edit-btn {
    color: #FBB03B;
}

.basic-card {
    width: 350px;
    margin-bottom: 16px;
    margin-right: 32px;
}

.addr-input {
    width: 100%;
    margin-bottom: 5px;
}

.addr-table {
    width: 100%;
    border: none;
    
    th {
        background-color: transparent;
        width: 120px;
        font-size: 14px;
        color: #333333;
        line-height: 16px;
        font-weight: normal;
        
        text-align: right;
    }
    
    td {
        text-align: left;
    }
    
    tr, th, td {
        border: none;
    }
}

.setting-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.documents {
    display: flex;
    justify-content: space-around;
    
    a {
        padding-right: 28px;
        display: inline-block;
        background-image: url("/assets/icons/document-arrow.png");
        background-size: 20px 20px;
        background-repeat: no-repeat;
        background-position: right;
        font-size: 14px;
        color: #FBB03B;
        line-height: 16px;
    }
}

.pdf-uploader {
    width: 150px;
}

</style>